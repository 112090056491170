import { LitElement, html, css } from 'lit';

const DEMO_INTRO_MESSAGE = { sender: 'bot', text: 'Greetings! This is Alfred. What can I help you with today?' };

import { t } from 'i18next';

class ChatBot extends LitElement {
	private isOpen: boolean;
	private messages: Array<any>;
	private userInput: string;

	static properties = {
		isOpen: { type: Boolean, attribute: false },
		messages: { type: Array },
		userInput: { type: String }
	};

	constructor() {
		super();
		this.isOpen = false;
		this.messages = [];
		this.userInput = '';
	}
	
	static styles = css`
		* {
			box-sizing: border-box;
		}

		:host {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100vh;
			max-width: 600px;
			margin: 0 auto;
			font-family: Arial, sans-serif;
			border-radius: 10px;
			overflow: hidden;
		}

		#open-chatbot-button-container {
			align-items: flex-end;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: flex-end;
			width: 100%;
		}

		#open-chatbot-speech-bubble {
			position: relative;
			background: var(--primary);
			border-radius: .4em;
			color: white;
			padding: 1em;
			max-width: 300px;
			text-align: center;
			animation: bounce 3s infinite;
		}

		#open-chatbot-speech-bubble:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 10%;
			width: 0;
			height: 0;
			border: solid transparent;
			border-color: rgba(0, 0, 0, 0);
			border-top-color: var(--primary);
			border-width: 10px;
			margin-left: -10px;
		}

		@keyframes bounce {
			0%, 20%, 50%, 80%, 100% {
				transform: translateY(0);
			}
			40% {
				transform: translateY(-20px);
			}
			60% {
				transform: translateY(-10px);
			}
		}

		#open-chat-button {
			cursor: pointer;
			height: 150px;
			width: 150px;
		}

		.chat-background {
			align-items: center;
			border: 1px solid lightgrey;
			background-color: #f9f9f9;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
		}

		.chat-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			left: auto;
			overflow: hidden;
			right: auto;
			width: 95%;
		}

		#chat-top-bar {
			align-items: center;
			display: flex;
			flex-direction: row;
			height: 2em;
			justify-content: space-between;
			padding: 5px;
		}

		#powered-by-alfred-message {
			color: var(--primary);
		}

		#powered-by-link {
			font-weight: 900;
			text-decoration: none;
		}

		#powered-by-link:hover {
			text-decoration: underline;
		}

		#hide-chat-button {
			cursor: pointer;
			color: var(--primary);
			font-weight: 900;
			font-family: 'Arial';
			font-size: 1.5em;
		}

		.chat-messages {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			gap: 10px;
			padding: 2.5em 20px 20px;
			overflow-y: auto;
			border-bottom: 1px solid #e0e0e0;
			background-color: #ffffff;
		}

		.message {
			margin-bottom: 10px;
			max-width: 80%;
			padding: 10px;
			border-radius: 10px;
			word-wrap: break-word;
		}

		.message.bot {
			align-self: flex-start;
			background-color: #e0f7fa;
			color: #00695c;
		}

		.message.user {
			align-self: flex-end;
			background-color: #c8e6c9;
			color: #1b5e20;
		}

		.chat-input {
			display: flex;
			padding: 10px;
			border-top: 1px solid #e0e0e0;
			background-color: #f9f9f9;
		}

		.chat-input input {
			flex-grow: 1;
			padding: 10px;
			border: 1px solid #e0e0e0;
			border-radius: 20px;
			font-size: 16px;
			margin-right: 10px;
			box-sizing: border-box;
		}

		.chat-input button {
			padding: 10px 15px;
			border: none;
			border-radius: 20px;
			background-color: #007bff;
			color: white;
			cursor: pointer;
			font-size: 16px;
			box-sizing: border-box;
		}

		.chat-input button:hover {
			background-color: #0056b3;
		}

		@media (max-width: 600px) {
			:host {
				max-width: 100%;
				border-radius: 0;
				height: 100vh;
			}

			.message {
				max-width: 100%;
				font-size: 16px;
			}

			.chat-input {
				flex-direction: row;
				padding: 5px;
			}

			.chat-input input {
				margin-right: 5px;
				width: calc(100% - 80px);
				font-size: 16px;
				padding: 10px;
			}

			.chat-input button {
				width: 70px;
				padding: 10px;
				font-size: 16px;
			}

			/* Ensuring the chat-container fits the screen height */
			.chat-container {
				height: 100vh;
			}
		}
	`;

	render() {
		if (!this.isOpen) {
			return html`
				<div id="open-chatbot-button-container">
					<div id="open-chatbot-speech-bubble">
						${t('chatWithAlfredNow')}
					</div>
					<img
						id="open-chat-button"
						src="${this._openChatButtonImage()}"
						@click="${this.onOpenChatButtonClick}"
					/>
				</div>
			`;
		}
		return html`
			<div class="chat-background">
				<div class="chat-container">
					<div id="chat-top-bar">
						${this.renderPoweredByMessage()}
						<div id="hide-chat-button" title="${t('hideChat')}" @click="${this.onHideChatButtonClick}">X</div>
					</div>
					<div class="chat-messages">
						${this.messages.map(message => html`
							<div class="message ${message.sender}">
								${message.text}
							</div>
						`)}
						<div id="scroll-anchor"></div>
					</div>
					<div class="chat-input">
						<input type="text" .value="${this.userInput}" @input="${this._onInput}" @keydown="${this._onKeydown}" placeholder="${t('textInputPlaceholder')}" />
						<button @click="${this._sendMessage}">${t('send')}</button>
					</div>
				</div>
			</div>
		`;
	}

	private renderPoweredByMessage() {
		return html`
			<p id="powered-by-alfred-message">
				Powered by <a id="powered-by-link" href="${process.env.POWERED_BY_URL}" target="_blank">Alfred</a>
			</p>
		`;
	}

	private onOpenChatButtonClick() {
		this.isOpen = true;
		if (this.messages.length === 0) {
			this.getIntroMessage();
		}
	}

	private getIntroMessage() {
		// TODO: Get intro message from the backend.
		const introMessage = DEMO_INTRO_MESSAGE;
		if (introMessage) {
			this.messages = [ ...this.messages, introMessage ];
		}
	}

	private onHideChatButtonClick() {
		this.isOpen = false;
	}

	_onInput(e: Event) {
		this.userInput = (e.target as HTMLInputElement).value;
	}

	_onKeydown(e: KeyboardEvent) {
		if (e.key === 'Enter') {
			this._sendMessage();
		}
	}

	_scrollToBottom() {
		const scrollAnchor = this.shadowRoot?.getElementById('scroll-anchor');
		if (scrollAnchor) {
			console.log('scrolling');
			scrollAnchor.scrollIntoView({ behavior: 'smooth' });
		}
	}

	_sendMessage() {
		if (this.userInput.trim() === '') return;

		const userMessage = this.userInput;

		this.messages = [...this.messages, { sender: 'user', text: userMessage }];
		this.userInput = '';
		// TODO: Find out why the auto-scroll doesn't work for user messages (but works for bot messages).
		this._scrollToBottom();

		if (process.env.USE_OFFLINE_RESPONSES === 'true') {
			this._getOfflineResponse(userMessage);
		} else {
			this._getBotResponse(userMessage);
		}
	}

	async _getBotResponse(input: string) {
		const response = await fetch(`${process.env.API_URL_PREFIX}/chat`, {
			method: 'POST',
			body: JSON.stringify({ message: input, To: 'https://hialfred.ca' }),
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const responseBody = await response.json();
		this.messages = [...this.messages, { sender: 'bot', text: responseBody.message }];

		setTimeout(() => {
			this._scrollToBottom();
		}, 500);
	}

	_getOfflineResponse(input: string) {
		setTimeout(() => {
			this.messages = [...this.messages, { sender: 'bot', text: `Got your message: ${input}` }];
			setTimeout(() => {
				this._scrollToBottom();
			}, 500);
		}, 1000);
	}

	_openChatButtonImage() {
		return new URL('./assets/open_chat_button.png', import.meta.url);
	}
}

customElements.define('chat-bot', ChatBot);

