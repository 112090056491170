import i18next, { t } from 'i18next';
import enTranslations from './lang/en';

import './chat-bot';

i18next.init({
	lng: 'en',
	resources: {
		en: { translation: enTranslations },
	},
});

